import React, { FC, ReactElement } from 'react';

import './NotFound.scss';

const NotFound: FC = (): ReactElement => (
  <section className="notFoundWrapper">
    <h1>Oops! That stopped you in your tracks..!</h1>
    <p>
      Sorry, the page you are looking for doesn’t exist. Please choose from the
      menu above.
    </p>
  </section>
);

export default NotFound;
