import React, { FC } from 'react';

import Layout from '../components/Layout/Layout';
import Notfound from '../templates/NotFound';

const NotFound: FC = () => (
  <div>
    <Layout>
      <Notfound />
    </Layout>
  </div>
);

export default NotFound;
